<template>
  <div>
    <TablePrintButton
        :loading="loading"
        :has-callback="true"
        :disabled="disabled"
        :variant="variant"
        @on-action-callback="tryPrintReport"
    />

    <BaseFormDialog
        v-if="showDialog"
        :title="getPrintDialogTitle"
        :open.sync="showDialog"
        :appendToBody="true"
        size="sm"
    >

      <base-form
          :show-cancel="true"
          :save-text="$t('Print')"
          :loading="loading"
          :focus-on-first-input="false"
          layout="modal"
          @cancel="showDialog = false"
          @submit="triggerPrint"
      >
        <base-select
            v-model="orientation"
            :label="$t('Select Print Orientation')"
            :options="reportPrintOrientationOptions"
            class="col-span-6"
        />
      </base-form>

    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { printAction } from '@/components/ag-grid/printUtils'
  import { previewFileInNewTab } from '@/modules/common/util/downloadFileLocally'
  import { reportNumbersMap, reportPrintOrientationOptions, reportPrintOrientations } from '@/utils/reports'
  import { checkIfRouteExists } from "@/modules/dashboard/util/routeUtils";
  import config from "@/modules/common/config";

  export default {
    props: {
      reportNumber: {
        type: [String, Number],
        default: '',
      },
      filters: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      variant: {
        type: String,
        default: 'primary-link'
      },
      transformFilters: {
        type: Function,
      }
    },
    data() {
      return {
        loading: false,
        showDialog: false,
        orientation: this.$store.getters['company/getPrintReportOrientation'] || reportPrintOrientations.Portrait,
        reportPrintOrientationOptions,
      }
    },
    computed: {
      authorizedToPrintRemote() {
        return reportNumbersMap[this.reportNumber]?.number
      },
      printV2() {
        return reportNumbersMap[this.reportNumber]?.printV2
      },
      reportHeader() {
        return this.$store.state.company.currentReportHeader
      },
      printOrientation() {
        return reportNumbersMap[this.reportNumber]?.printOrientation
      },
      headerName() {
        let documentTitle = document.title
        documentTitle = documentTitle.split('-')[0] || ''
        return reportNumbersMap[this.reportNumber]?.name || this.reportHeader.title || documentTitle
      },
      getPrintDialogTitle() {
        let title = `Print Report`

        if (!this.reportNumber && !this.reportHeader?.title) {
          title = `Print`
        }
        return this.$t(`${title} - ${this.headerName}`)
      },
    },
    methods: {
      getReportFileName() {
        const now = new Date()
        const date = this.$formatDate(now)
        const time = this.$formatDate(now, 'HH:mm:ss')
        const companyName = this.reportHeader.company?.name || this.$currentCompany.name
        const reportName = this.headerName
        return `${reportName} - ${companyName} - ${date} ${time}`
      },
      async tryPrintReport() {
        if (!this.authorizedToPrintRemote && !this.authorizedToPrintV2()) {
          return this.printSnapshot()
        }

        if (this.printOrientation) {
          this.orientation = this.printOrientation
          this.triggerPrint()
          return
        }

        this.showDialog = true
      },
      async triggerPrint() {
        if (!this.authorizedToPrintRemote || this.printV2) {
          await this.printRemoteV2()
        } else {
          await this.printRemote()
        }
      },
      getPrintReportRoute() {
        // All print routes are prefixed with /print. E.g `/print/trial-balance`
        const path = this.$route.path
        let parts = path.split('/')
        let routePath = parts[parts.length - 1]
        if (routePath.includes('proof-listing')) {
          routePath = `${parts[parts.length - 2]}/${routePath}`
        }
        if (path.includes('timecard-batches')) {
          routePath = `timecard-batches/${this.$route.params.id}/proof-listing`
        } else if (path.includes('positive-pay')) {
          routePath = `batches/${this.$route.params.id}/positive-pay`
        } else if (path.includes('payroll/batches')) {
          routePath = `batches/${this.$route.params.id}/summary`
        }

        return {
          path: `/print/${routePath}`,
          query: this.$route.query
        }
      },
      authorizedToPrintV2() {
        const printReportRoute = this.getPrintReportRoute()
        return checkIfRouteExists(printReportRoute)
      },
      async printRemoteV2() {
        const printReportRoute = this.getPrintReportRoute()
        const resolvedUrl = this.$router.resolve(printReportRoute).href
        const url = window.location.origin + resolvedUrl
        let apiUrl = `${config.PDF_API_URL}/generate-pdf`
        if (window.location.origin.includes('localhost')) {
          apiUrl = `http://localhost:3000/generate-pdf`
        }

        try {
          this.loading = true
          this.showDialog = false

          this.$success(this.$t('The file will be generated in a few moments, please wait.'))

          const fileName = this.getReportFileName()
          const scale = reportNumbersMap[this.reportNumber]?.printScaling?.[this.orientation]
          const pdfData = await axios.post(apiUrl, {
            localStorage: {
              token: localStorage.getItem('token'),
              vuex: localStorage.getItem('vuex'),
              selectedAPInvoicesToPay: localStorage.getItem('selectedAPInvoicesToPay'),
            },
            scale,
            orientation: this.orientation,
            url,
            fileName,
          }, { responseType: 'blob' })

          previewFileInNewTab(pdfData, fileName)

        } catch (err) {
          if (err.handled) {
            return
          }
          if (err.message === 'Network Error' && apiUrl.includes('localhost')) {
            this.$error(this.$t('Ensure the PDF API is running on port 3000. See README.md for more details.'))
          } else {
            this.$error(this.$t('Could not print report. Please try again.'))
          }
        } finally {
          this.loading = false
        }
      },
      async printRemote() {
        try {
          this.loading = true
          this.showDialog = false

          this.$success(this.$t('The file will be generated in a few moments, please wait.'))

          let payload = {}

          for (const [key, value] of Object.entries(this.filters)) {
            if (value || value === 0 || value === false) {
              payload[key] = value
            }
          }

          payload['orientation'] = this.orientation

          if (this.transformFilters) {
            payload = this.transformFilters(payload)
          }

          const url = `/reports/print-report-${this.reportNumber}`

          const pdfData = await axios.post(url, payload, {
            responseType: 'blob',
          })

          previewFileInNewTab(pdfData)

        } catch (err) {
          if (err.handled) {
            return
          }

          console.warn(err)

          this.$error(this.$t('Could not print report. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      printSnapshot() {
        printAction()
      },
    },
  }
</script>
