<template>
  <div class="shadow px-4 pb-4 rounded bg-white print:shadow-none print:p-0">
    <AgDataTable
      :url="url"
      :compact="true"
      :columns="allColumns"
      :url-params="urlParams"
      :default-sort="defaultSort"
      :data-loading="dataLoading"
      :add-text="$t('New line item')"
      :disable-col-flex="true"
      :import-url="`/job-costing/line-items/import?jobId=${jobId}`"
      :exclude-filters="['last_updated_at']"
      permission="line_items"
      dom-layout="autoHeight"
      hide-actions="delete,view,edit"
      actions="search,add,refresh,import,export,bulk-delete"
      entity="line-items"
      class="print:mt-4"
      ref="table"
      :transform-data="transformData"
      @add="showAddLineItemDialog = true"
    >

      <template #additional-actions-before>
        <BaseSelect
          v-model="lineItemType"
          :label="$t('Line Item Type')"
          :placeholder="$t('Line Item Type')"
          :add-entity="false"
          :options="lineItemTypeOptions"
          @change="onItemTypeChanged"
        />
        <BaseSelect
          v-model="budgetTypeIds"
          :label="$t('Budget Types')"
          :placeholder="$t('All')"
          :add-entity="false"
          :collapse-tags="true"
          multiple
          :options="budgetTypeOptions"
        />
      </template>

      <template #attributes.description="{row}">
        <router-link :to="getLineItemLink(row)">
          {{ row.attributes.description || '' }}
        </router-link>
      </template>

    </AgDataTable>

    <LineItemDialog
      v-if="showAddLineItemDialog"
      :open.sync="showAddLineItemDialog"
      :job="currentJob"
      :defaultItemType="lineItemType"
      @save="onLineItemAdded"
      @close="showAddLineItemDialog = false"
    />
  </div>
</template>
<script>
import axios from 'axios'
import { get, set, debounce } from 'lodash'
import { JobTypeFor } from "@/modules/job-costing/enum/jobs";
import { costTypes } from '@/enum/enums';
import { globalResources } from "@/components/form/util";
import { validateNumber, validateMaxDecimals } from '@/modules/common/util/validators';
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses } from '@/components/ag-grid/columnUtils';
import LineItemDialog from '@/modules/job-costing/components/line-items/LineItemDialog'

export default {
  name: 'ManageJobLineItems',
  components: {
    LineItemDialog,
  },
  props: {
    canUpdatePath: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultSort: 'phase_code,cost_code,change_order',
      selectedBudget: {},
      dataLoading: false,
      showAddLineItemDialog: false,
      lineItemType: costTypes.Income,
      budgetTypeIds: [],
      lineItemTypeOptions: [
        {
          label: this.$t('Cost Line Items'),
          value: costTypes.Cost,
        },
        {
          label: this.$t('Income Line Items'),
          value: costTypes.Income,
        },
      ],
      columns: [
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 300,
          maxWidth: 500,
          pinned: 'left',
          editable: true,
          valueSetter: this.saveLineItemDetails
        },
        {
          headerName: this.$t('Type'),
          field: 'attributes.type',
          align: 'center',
          minWidth: 40,
          maxWidth: 60,
          component: 'Status',
        },
        {
          headerName: this.$t('Phase'),
          field: 'attributes.phase_code',
          minWidth: 60,
          maxWidth: 80,
          editable: true,
          valueSetter: this.saveLineItemDetails
        },
        {
          headerName: this.$t('Cost Code'),
          field: 'attributes.cost_code',
          minWidth: 80,
          maxWidth: 80,
          editable: true,
          valueSetter: this.saveLineItemDetails
        },
        {
          headerName: this.$t('Chg Order'),
          field: 'attributes.change_order',
          minWidth: 80,
          maxWidth: 80,
          align: 'center',
          editable: true,
          cellEditor: cellEditors.Numeric,
          cellEditorParams: {
            step: '0.01'
          },
          valueSetter: (params) => {
            if (!validateNumber(params.newValue, { notifyErr: true })) {
              return false
            }
            
            this.saveLineItemDetails(params)
          }
        },
      ],
      allColumns: [],
      refreshColumnsDebounced: null
    }
  },
  computed: {
    jobId() {
      return this.$route.params.id
    },
    currentJob() {
      return this.$store.state.jobCosting.currentJob
    },
    url() {
      return '/restify/line-items'
    },
    urlParams() {
      return {
        job_id: this.jobId,
        related: 'budgets',
        type: this.lineItemType,
      }
    },
    jobTypes() {
      return this.$store.getters['globalLists/getResourceList'](globalResources.JobTypes) || []
    },
    incomeJobTypes() {
      return this.jobTypes.filter((jobType) => jobType.type === costTypes.Income)
    },
    costJobTypes() {
      return this.jobTypes.filter((jobType) => jobType.type === costTypes.Cost)
    },
    budgetTypeOptions() {
      if (this.lineItemType === costTypes.Income) {
        return this.incomeJobTypes.map(jobType => ({
          label: jobType.name,
          value: jobType.id,
        }))
      }

      return this.costJobTypes.map(jobType => ({
        label: jobType.name,
        value: jobType.id,
      }))
    },
  },
  methods: {
    transformData(data) {
      return data.map(this.lineItemMapper)
    },
    lineItemMapper(lineItem) {
      const budgets = get(lineItem, 'relationships.budgets', [])
      const item = {
        ...lineItem,
      }

      budgets.forEach((budget) => {
        set(item, `budgets.${budget.attributes.job_type_id}`, budget.attributes)
      })

      return item
    },
    getIncomeBudgetColumns() {
      let cols = []

      this.incomeJobTypes.forEach((jobType) => {
        if (this.isColumnHidden(jobType)) {
          return
        }

        const editable = jobType.for !== JobTypeFor.Income.UnitPrice

        cols.push({
          headerName: jobType.name,
          field: `budgets.${jobType.id}.amount`,
          jobTypeId: jobType.id,
          align: 'right',
          minWidth: 100,
          maxWidth: 150,
          editable,
          cellEditor: cellEditors.Numeric,
          cellEditorParams: {
            step: '0.01'
          },
          valueSetter: (params) => {
            if (!validateNumber(params.newValue, { notifyErr: true })) {
              return false
            }

            if (!validateMaxDecimals(params.newValue, 2, { notifyErr: true })) {
              return false
            }

            this.saveOrUpdateBudget(params)
          },
          cellClass: editable ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        })

        if (jobType.for === JobTypeFor.Income.UnitPrice) {
          cols.push({
            headerName: `${jobType.for} Qty`,
            field: `budgets.${jobType.id}.quantity`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            editable: true,
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }

              if (!validateMaxDecimals(params.newValue, 3, { notifyErr: true })) {
                return false
              }

              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedQuantity',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            },
          })

          cols.push({
            headerName: `${jobType.for} Measure`,
            field: `budgets.${jobType.id}.um`,
            jobTypeId: jobType.id,
            align: 'left',
            minWidth: 100,
            maxWidth: 150,
            valueSetter: this.saveOrUpdateBudget,
            editable: true,
          })
          
          cols.push({
            headerName: `${jobType.for} Price`,
            field: `budgets.${jobType.id}.unit_rate`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            editable: true,
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }

              if (!validateMaxDecimals(params.newValue, 2, { notifyErr: true })) {
                return false
              }

              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedPrice',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          })

        }
      })

      return cols
    },
    getCostBudgetColumns() {
      const cols = []
      
      this.costJobTypes.forEach(jobType => {
        if (this.isColumnHidden(jobType)) {
          return
        }

        cols.push({
          headerName: jobType.name,
          field: `budgets.${jobType.id}.amount`,
          jobTypeId: jobType.id,
          align: 'right',
          minWidth: 100,
          maxWidth: 150,
          editable: true,
          cellEditor: cellEditors.Numeric,
          cellEditorParams: {
            step: '0.01'
          },
          valueSetter: (params) => {
            if (!validateNumber(params.newValue, { notifyErr: true })) {
              return false
            }

            if (!validateMaxDecimals(params.newValue, 2, { notifyErr: true })) {
              return false
            }

            this.saveOrUpdateBudget(params)
          },
          component: 'FormattedPrice',
        })

        if (jobType.for === JobTypeFor.Cost.Equipment) {
          cols.push({
            headerName: `${jobType.for} Hours`,
            field: `budgets.${jobType.id}.quantity`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            editable: true,
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }

              if (!validateMaxDecimals(params.newValue, 3, { notifyErr: true })) {
                return false
              }

              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedHours',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            },
          })
        }

        if (jobType.for === JobTypeFor.Cost.Labor) {
          cols.push({
            headerName: `${jobType.for} Hours`,
            field: `budgets.${jobType.id}.quantity`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            editable: true,
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }

              if (!validateMaxDecimals(params.newValue, 3, { notifyErr: true })) {
                return false
              }

              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedHours',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            },
          })
        }

        if (jobType.for === JobTypeFor.Cost.Material) {
          cols.push({
            headerName: `${jobType.for} Units`,
            field: `budgets.${jobType.id}.quantity`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            editable: true,
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }

              if (!validateMaxDecimals(params.newValue, 3, { notifyErr: true })) {
                return false
              }

              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedQuantity',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            },
          })
          cols.push({
            headerName: `${jobType.for} Measure`,
            field: `budgets.${jobType.id}.um`,
            jobTypeId: jobType.id,
            align: 'left',
            minWidth: 100,
            maxWidth: 150,
            valueSetter: this.saveOrUpdateBudget,
            editable: true,
          })
        }
      })

      return cols
    },
    isColumnHidden(jobType) {
      if (!this.budgetTypeIds.length) {
        return false
      }

      return !this.budgetTypeIds.includes(jobType.id)
    },
    async refreshColumns() {
      await this.$nextTick()

      const typeColumns = this.lineItemType === costTypes.Income
        ? this.getIncomeBudgetColumns()
        : this.getCostBudgetColumns()

      this.allColumns = [
        ...this.columns,
        ...typeColumns
      ]
    },
    refreshTable() {
      this.$refs.table.refresh({}, true)
    },
    async saveLineItemDetails(params) {
      const field = params.colDef.field
      const newValue = params.newValue
      const lineItem = params.data

      set(lineItem, field, newValue)
      
      const updateModel = {
        [field.replace('attributes.', '')]: newValue,
      }

      await axios.patch(`/restify/line-items/${lineItem.id}`, updateModel)
    },
    async saveOrUpdateBudget(params) {
      const field = params.colDef.field
      const newValue = params.newValue
      const lineItem = params.data
      const jobTypeId = params.colDef.jobTypeId

      set(lineItem, field, newValue)
      
      const budget = lineItem.budgets[jobTypeId]

      const jobType = this.jobTypes.find(jobType => jobType.id === jobTypeId)

      if (jobType.for === JobTypeFor.Income.UnitPrice) {
        const amount = (budget?.quantity || 0) * (budget?.unit_rate || 0)
        set(lineItem, `budgets.${jobTypeId}.amount`, amount)
      }

      if (budget?.id) {
        await this.updateBudgetEntry(params)
      }
      else {
        await this.createBudgetEntry(params)
      }
    },
    async createBudgetEntry(params) {
      const field = params.colDef.field
      const jobTypeId = params.colDef.jobTypeId
      const newValue = params.newValue

      if (params.data.creating) {
        params.data.updates = params.data.updates || []

        params.data.updates.push({
          jobTypeId,
          field,
          newValue,
        })

        return
      }

      try {
        params.data.creating = true

        const model = this.getUpdateModel(params.data, jobTypeId, field, newValue)

        const response = await axios.post(`/restify/job-budgets/bulk`, [model])
        const savedBudget = get(response, 'data[0]')

        set(params.data, `budgets.${model.job_type_id}`, savedBudget)

        if (params.data.updates?.length) {
          await this.applyPostCreateUpdates(params.data, params.data.updates)
        }
      }
      finally {
        params.data.creating = false
      }
    },
    async updateBudgetEntry(params) {
      const field =  params.colDef.field
      const jobTypeId = params.colDef.jobTypeId
      const newValue = params.newValue

      const model = this.getUpdateModel(params.data, jobTypeId, field, newValue)

      await axios.post(`/restify/job-budgets/bulk/update`, [model])
    },
    async applyPostCreateUpdates(lineItem, updates) {
      let model = {}

      // Merge all updates into a single model
      updates.forEach(update => {
        const field = update.field
        const newValue = update.newValue
        const jobTypeId = update.jobTypeId

        set(lineItem, field, newValue)

        const updateModel = this.getUpdateModel(lineItem, jobTypeId, field, newValue)

        model = {
          ...model,
          ...updateModel,
        }
      })

      await axios.post(`/restify/job-budgets/bulk/update`, [model])
    },
    getUpdateModel(lineItem, jobTypeId, field, newValue) {
      const budgetProp = field.split('.').pop()
      const budget = lineItem.budgets[jobTypeId]

      const defaultValues = {
        amount: 0,
        quantity: 0,
        unit_rate: 0,
        um: '',
      }

      return {
        id: budget.id,
        job_type_id: jobTypeId,
        line_item_id: lineItem.id,
        ...defaultValues,
        ...budget,
        [budgetProp]: newValue,
      }

    },
    onLineItemAdded() {
      this.showAddLineItemDialog = false
      this.refreshTable()
    },
    onItemTypeChanged() {
      this.budgetTypeIds = []
    },
    getLineItemLink(row) {
      const { id, type } = row.attributes
      return `/job-costing/${type}-line-items/${id}/view?fromJob=${this.jobId}`
    },
  },
  watch: {
    lineItemType: {
      handler() {
        this.refreshColumns()
      },
      immediate: true,
    },
    budgetTypeIds() {
      this.refreshColumnsDebounced?.()
    },
    jobTypes() {
      this.refreshColumnsDebounced?.()
    }
  },
  created() {
    this.refreshColumnsDebounced = debounce(this.refreshColumns, 100)
  }
}
</script>
